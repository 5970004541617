<template>
  <base-popup
    v-if="dialog"
    :title="'Вопрос №' + num"
    :disabledSave="!valid"
    @save="save"
    v-model="dialog"
    :icon="UtilGetIcon('view-TestEdit')"
  >
    <v-container>
      <v-form v-model="valid">
        <question-item-edit class="mb-5" :value="data" autofocus />
        <div class="testing-question__add-label">
          <div></div>
          <div>Правильный ответ</div>
          <div></div>
        </div>
        <question-item-edit
          v-for="(item, index) in data.Variants"
          :key="index"
          :value="item"
          show-expend
          class="mb-5"
          :label="'Ответ ' + (index + 1)"
          :is-deletable="true"
          @delete="delVariation"
        />

        <v-btn @click="add" text color="primary" large>
          <m-icon
            icon="mdi-plus-circle"
            left
            style="font-size: 30px"
            class="mr-3"
          ></m-icon>
          Добавить ответ
        </v-btn>
      </v-form>
    </v-container>
  </base-popup>
</template>
<script>
import BasePopup from "@/layouts/BasePopup.vue";
import QuestionItemEdit from "./QuestionItemEdit.vue";

import { KnowledgeBase } from "@/data/defaultObjects";
export default {
  components: {
    BasePopup,
    QuestionItemEdit,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Boolean,
      default: false,
    },
    question: {
      type: Object,
      default: () => {},
    },
    questionCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      data: this.question || KnowledgeBase.question(),
      valid: false,
    };
  },
  computed: {
    num() {
      return this.question?.Num || this.questionCount + 1;
    },
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.data = this.question || KnowledgeBase.question();
      }
    },
  },
  methods: {
    add() {
      this.data.Variants.push(KnowledgeBase.variant());
    },
    save() {
      // this.data.Variants = this.data.Variants.filter((e) => e.Text);
      if (!this.data.Variants.find((e) => e.IsCorrect)) {
        this.$alert("Укажите хоть один правильный ответ");
        return;
      }
      this.data = { ...this.data, Num: this.questionCount + 1 };
      this.$emit("save", this.data);
      this.dialog = false;
    },
    delVariation(item) {
      this.data.Variants = this.data.Variants.filter((e) => e.Id !== item.Id);
    },
  },
};
</script>
<style lang="scss">
.testing-question__add-label {
  display: flex;
  margin-top: 40px;
  margin-bottom: 20px;
  div:nth-child(1) {
    flex-grow: 1;
  }

  div:nth-child(2) {
    width: 150px;
    font-weight: 500;
    font-size: 14px;
    line-height: 158%;
    color: var(--v-text-base);
    letter-spacing: -0.01em;
    text-align: center;
  }

  div:nth-child(3) {
    width: 72px;
  }
}
</style>
